import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

import "../styles/support.scss";

import logo from '../images/logo.png';
import { Link } from "gatsby";

const IndexPage = () => (
  <Layout>
    <SEO title="Support" />

    <div claclassNamess="support">
      <h1>Support</h1>
      <p>For support, please e-mail <a href="mailto:support@theellisapp.com">support@theellisapp.com</a>.</p>

      <div className="logo">
        <Link to="/"><img
          src={logo}
          alt="" /></Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
